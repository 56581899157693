<template>
  <v-card flat>
    <v-card-title class="pa-0">
      <v-container fluid pa-0>
        <v-row no-gutters class="justify-end">
          <v-col class="align-self-center pa-4">
            <span>Reset Password</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-container fluid pa-0 fill-height>
        <v-row no-gutters class="pb-3">
          <v-col
            cols="6"
            sm="6"
            xs="6"
            md="6"
            lg="2"
            xl="2"
            class="text-left pr-2 align-self-center"
          >
            <span>Email</span>
          </v-col>
          <v-col cols="6" class="text-start">
            <v-text-field
              outlined
              autofocus
              :rules="emailRules"
              hide-details="auto"
              label="Email address"
              readonly
              background-color="grey lighten-3"
              v-model="user.email"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid pa-0 fill-height>
          <v-row no-gutters>
            <v-col
              cols="6"
              sm="6"
              xs="6"
              md="6"
              lg="2"
              xl="2"
              class="text-left pr-2 align-self-center"
            >
              <span>Password</span>
            </v-col>
            <v-col cols="6" class="text-start">
              <v-text-field
                outlined
                v-model="password"
                label="Password"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                hide-details="auto"
                :rules="passwordRules"
                :type="showPassword ? 'text' : 'password'"
                name="Password"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-2">
            <v-col
              cols="6"
              sm="6"
              xs="6"
              md="6"
              lg="2"
              xl="2"
              class="text-left pr-2 align-self-center"
            >
              <span>Confirm Password</span>
            </v-col>
            <v-col cols="6" class="text-start">
              <v-text-field
                outlined
                :type="'password'"
                v-model="confirmPassword"
                :rules="confirmPasswordRules"
                :error-messages="confirmPasswordErrMsgs"
                label="Confirm Password"
                hide-details="auto"
                name="Confirm Password"
                v-on:keyup.enter="login"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn text @click="cancelResetPassword">Cancel</v-btn>
      <v-btn
        color="primary"
        class="elevation-0"
        :loading="resetPasswordBtnLoading"
        :disabled="!disableResetPasswordBtn"
        @click="resetPassword"
        >Save</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { EventBus } from "@/lib/EventBus";
import * as API from "@/api/api";
import { mapGetters } from "vuex";
export default {
  name: "LoginView",
  components: {},
  data() {
    return {
      valid: true,
      password: "",
      confirmPassword: "",
      confirmPasswordErrMsgs: [],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      confirmPasswordRules: [
        (v) => !!v || "Password is required",
        (v) => v == this.password || "Passwords does not match",
      ],
      showPassword: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "authStore/getterLoginUser",
    }),
    resetPasswordBtnLoading() {
      return this.$store.getters["pinaStore/getterLoadingStatus"](
        "RESET_PASSWORD"
      );
    },
    disableResetPasswordBtn() {
      return (
        this.password != "" &&
        this.confirmPassword != "" &&
        this.password == this.confirmPassword
      );
    },
  },
  watch: {},
  mounted() {},
  methods: {
    async resetPassword() {
      try {
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "RESET_PASSWORD",
          pending: true,
        });
        let postData = {
          uid: this.user.uid,
          me: this.user.email,
          pwp: this.confirmPassword,
        };
        const resetPasswordData = await API.updatePassword(postData);
        console.log(resetPasswordData);
        if (resetPasswordData?.data?.status) {
          this.$refs.form.reset();
        }
        this.$store.commit("pinaStore/SET_LOADING_STATUS", {
          property: "RESET_PASSWORD",
          pending: false,
        });

        EventBus.$emit("success-reset-password");
        this.$notify({
          group: "divrt",
          title:
            resetPasswordData?.data?.status && resetPasswordData.data.status
              ? "Success"
              : "Error",
          text: resetPasswordData?.data?.message
            ? resetPasswordData.data.message
            : "",
          type:
            resetPasswordData?.data?.status && resetPasswordData.data.status
              ? "success"
              : "error",
        });
      } catch (error) {
        console.log(error);
      }
    },
    cancelResetPassword() {
      this.$router.go(-1);
    },
  },
};
</script>


































